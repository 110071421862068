import { UpdateUserQuery } from "../models/queries/updateUserQuery.ts";
import { ResetOTPResponse } from "../models/responses/otpLoginResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorsService } from "./errorsService.ts";
import Resources from "../models/resources.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";
import { AccessWithOtpQuery } from "../models/queries/otpLoginQuery.ts";

export class AccessWithOtpService extends BackendServiceBase<AccessWithOtpQuery, ResetOTPResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.UpdateField);
    }

    protected override defineErrorMessage(query: AccessWithOtpQuery): string {
        return this.resources.errors.updatePassword;
    }
    protected buildFetch(headers: any, query?: AccessWithOtpQuery | undefined): Promise<Response> {
        return fetch(`${this.backendEndpoint}/ChangePasswordWithOtp`, {
            headers: headers,
            method: 'PATCH',
            body: JSON.stringify(query)
        });
    }
}