<template>
    <div class="rsc-onetrust-cookie-footer">
        <div id="rsc-onetrust-cookie-footer-non-br">This website collects cookies to deliver a better user experience.
            <span id="rsc-onetrust-cookie-footer-global">
                See how this site uses <a id="linkCookies" href="/cookies" target="_blank">cookies</a>.
            </span>
            <span id="rsc-onetrust-cookie-footer-ca" style="display: none;">
                <a id="linkCookiesCa" href="/cookies" target="_blank">Do not sell
                    my personal data</a>.
            </span>
        </div>
        <div id="rsc-onetrust-cookie-footer-br" style="display: none;">
            Este site coleta cookies para oferecer uma melhor experiência ao usuário.
            <span>
                Veja como este site usa <a id="linkCookiesBr" href="/cookies" target="_blank">cookies</a>.
            </span>
        </div>
    </div>
</template>
<style scoped>

/* RSC OneTrust styles */
.rsc-onetrust-cookie-settings {
    text-align: right;
}

.rsc-onetrust-cookie-policy #ot-sdk-btn.ot-sdk-show-settings {
    background-color: #FFFFFF !important;
    border: 1px solid #147098 !important;
    color: #147098 !important;
    font-size: 1em !important;
    line-height: 1em !important;
}

.rsc-onetrust-cookie-policy #ot-sdk-btn.ot-sdk-show-settings:hover {
    background-color: #147098 !important;
    border: 1px solid #147098 !important;
    color: #FFFFFF !important;
    font-size: 1em !important;
    line-height: 1em !important;
}

.rsc-onetrust-cookie-policy a {
    color: #147098 !important;
    text-decoration: none !important;
}

.rsc-onetrust-cookie-policy a:hover {
    color: #004976 !important;
    text-decoration: underline !important;
}

.rsc-onetrust-cookie-footer {
    background-color: var(--primary-bg);
    padding: 1em;
    padding-bottom: 0px;
    text-align: center;
}

.rsc-onetrust-cookie-footer a:link,
.rsc-onetrust-cookie-footer a:visited {
    color: #004976;
    font-size: 18px;
}

.rsc-onetrust-cookie-footer a:hover {
    text-decoration: underline;
}
</style>