<template>
  <div
    class="flex flex-col"
    data-testid="form-select">
    <div class="items-top flex justify-between">
      <label
        :for="id"
        data-testid="select-label"
        class="font-bold"
        :class="[disabled ? 'text-royal-blue-20' : 'text-royal-blue-160']"
      >
        {{ label }}
      </label>
      <span
        v-if="isOptional"
        data-testid="select-optional"
        :class="[disabled ? 'text-royal-blue-20' : 'text-royal-blue-160']"
      >
        optional
      </span>
    </div>
    <template v-if="helpText">
      <p
        :id="helpTextId"
        data-testid="select-help-text"
        class="mb-2"
        :class="[
          disabled
            ? 'cursor-not-allowed text-royal-blue-20'
            : 'cursor-pointer text-royal-blue-160',
        ]"
        v-html="helpText"
      >
      </p>
    </template>
    <div class="group relative flex items-center">
      <select
        :id="id"
        ref="select"
        data-testid="select-field"
        :value="modelValue"
        @input="handleInput($event.target.value)"
        @change="$emit('custom-change', $event)"
        @focus="$emit('custom-focus', $event)"
       @blur="() => { validateSelection(); $emit('custom-blur', $event); }"
        :disabled="disabled"
        :required="!isOptional"
        :aria-describedby="describedById || undefined"
        :aria-invalid="isInvalid"
        :aria-errormessage="errorId"
        class="block w-full appearance-none rounded border-1 border-[#999999] bg-white px-4 py-[7px] placeholder-graphite focus:outline-none"
        :class="{
          'text-graphite hover:border-royal-blue-100 focus:border-[#000000] focus:border-2':
            !disabled,
          'cursor-not-allowed border-royal-blue-20 text-royal-blue-20 placeholder-royal-blue-20 hover:border-royal-blue-20 focus:border-royal-blue-20':
            disabled,
          'border-[#EC0046] hover:border-[#EC0046] focus:border-[#EC0046]':
            errorMessage,
        }"
      >
        <option
          disabled
          value="">
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <ChevronDownIcon
        class="pointer-events-none absolute right-[10px] text-graphite group-hover:text-royal-blue-100"
        :class="{
          'text-ruby-red-100 group-hover:text-ruby-red-100': errorMessage,
          'text-royal-blue-20 group-hover:text-royal-blue-20': disabled,
        }"
      />
    </div>
    <div :aria-live="errorAriaLive">
      <div
        v-if="isInvalid"
        :id="errorId"
        class="items-top py-2 flex space-x-2 px-4">
        <WarningTriangleIcon class="flex-shrink-0 text-[#EC0046]" />
        <p data-testid="select-error-message" class="mb-4 ml-8 text-[12px] mt-2 relative before:content-['•'] before:absolute before:left-[-15px] before:top-[-4px] before:text-black before:text-[18px]">
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from '../../assets/icons/ChevronDownIcon.vue'
import WarningTriangleIcon from '../../assets/icons/WarningTriangleIcon.vue'

/** Select components are used for collecting user provided information from a list of options. */
export default {
  name: 'FormSelect',
  props: {
    id: {
      type: String,
      default: '',
    },
    /** Communicates the content the user needs to enter in the field. */
    label: {
      type: String,
      default: '',
    },
    /** Communicates to the user that entering content is optional for this field. */
    isOptional: {
      type: Boolean,
      default: false,
    },
    /** The content the user has entered into the field. */
    modelValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    errorAriaLive: {
      /** Choose how error messages are announced; interrupting or otherwise. Consideration for scenarios when multiple Form Components may be invalid simulataneously and to not overwhelm. */
      type: String,
      validator: value => ['polite', 'assertive', 'off'].includes(value),
      default: 'polite',
    },
    /** Provides additional feedback about what the user is required to do. */
    helpText: {
      type: String,
      default: null,
    },
  },
  computed: {
    isInvalid() {
      return !!this.errorMessage
    },
    errorId() {
      return this.isInvalid ? `error-${this.id}` : null
    },
    helpTextId() {
      return this.helpText ? `help-${this.id}` : null
    },
    describedById() {
      // to maximise SR support, errorId used in aria-describedBy & aria-errormessage attributes
      return [this.errorId, this.helpTextId].filter(id => id).join(' ')
    },
  },
  components: {
    WarningTriangleIcon,
    ChevronDownIcon,
  },
  methods: {
  handleInput(value) {
    const numericValue = Number(value);
    this.$emit(
      'update:modelValue',
      isNaN(numericValue) ? value : numericValue,
    );
  },
  validateSelection() {
    if (!this.modelValue) {
      this.$emit('validation-error', 'Please select an option');
    } else {
      this.$emit('validation-error', null);
    }
  },
},
watch: {
  modelValue() {
    if (this.modelValue) {
      // Emit an event to inform the parent that the selection is valid
      this.$emit('validation-error', null);
    }
  }
},
  emits: ['update:modelValue', 'custom-change', 'custom-focus', 'custom-blur'], // custom-* events available for potential input validation/error message handling
}
</script>
