class TokenService {
    private static password: string | null = null;
    private static email: string | null = null;
    private static state: string | null = null;

    public static setPasswordEmail(passwordSet: string, emailSet: string, stateSet: string): void {
        TokenService.password = passwordSet;
        TokenService.email = emailSet;
        TokenService.state = stateSet;

    }

    public static getPassword(): string | null {
        return TokenService.password;
    }

    public static getEmail(): string | null {
        return TokenService.email;
    }

    public static getState(): string | null {
        return TokenService.state;
    }
}


export default TokenService;
