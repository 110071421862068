import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "custom-main-content" }
const _hoisted_2 = { class: "componentTitle" }
const _hoisted_3 = { class: "cardMainContent" }
const _hoisted_4 = { class: "descriptionTextMb" }
const _hoisted_5 = { class: "descriptionTextMb" }
const _hoisted_6 = { class: "descriptionTextMb" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = {
  key: 0,
  class: "errors-container"
}
const _hoisted_9 = { class: "errors-list" }
const _hoisted_10 = {
  key: 0,
  class: "error-item"
}
const _hoisted_11 = { class: "error-message" }
const _hoisted_12 = { class: "error-delete" }
const _hoisted_13 = {
  key: 1,
  class: "error-item"
}
const _hoisted_14 = { class: "error-message" }
const _hoisted_15 = { class: "error-delete" }
const _hoisted_16 = { class: "buttonsBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_BlockableButton = _resolveComponent("BlockableButton")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.checkYourEmail), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.otpEmailSent), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.otpValid), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.enterCode), 1),
      _createVNode(_component_v_form, {
        "validate-on": "submit",
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
        modelValue: _ctx.isValid,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.otpCodeLabel), 1),
            _createVNode(_component_v_text_field, _mergeProps({ class: "input" }, _ctx.vProps, {
              modelValue: _ctx.otpCodeValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otpCodeValue) = $event)),
              rules: _ctx.otpCodeRules,
              "validate-on": "blur"
            }), null, 16, ["modelValue", "rules"]),
            (_ctx.otpCodeWrong || _ctx.hasAccountReachedItsLimit || _ctx.otpCodeExpired)
              ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.otpCodeWrong)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createTextVNode(_toDisplayString(_ctx.otpCodeWrongText) + " ", 1),
                            _createElementVNode("a", {
                              class: "card-link-text click-here-link",
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resendCode && _ctx.resendCode(...args)))
                            }, _toDisplayString(_ctx.requestNewCodeText), 1),
                            _createTextVNode(". ")
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", {
                              class: "error-delete-icon",
                              onClick: _cache[2] || (_cache[2] = () => _ctx.otpCodeWrong = false)
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.otpCodeExpired)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createTextVNode(_toDisplayString(_ctx.otpCodeExpiredMessage) + " ", 1),
                            _createElementVNode("p", null, [
                              _createElementVNode("a", {
                                class: "card-link-text click-here-link",
                                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.resendCode && _ctx.resendCode(...args)))
                              }, _toDisplayString(_ctx.requestNewCodeText), 1),
                              _createTextVNode(".")
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", {
                              class: "error-delete-icon",
                              onClick: _cache[4] || (_cache[4] = () => _ctx.otpCodeExpired = false)
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_ErrorsManager, { errorTypes: _ctx.errorTypes }, null, 8, ["errorTypes"]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_BlockableButton, {
              idTag: "changePasswordBack",
              route: "/resetPassword",
              buttonText: _ctx.buttonBack,
              underlined: ""
            }, null, 8, ["buttonText"]),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "submit",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
                  class: "blueBgButton"
                }, _toDisplayString(_ctx.buttonSave), 1))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}