
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import ErrorsManager from "../shared/errorsManager.vue";
import RadialSpinner from "../shared/radialSpinner.vue";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import { ErrorType } from "@/core/models/metadata/error.ts";
import { StringHelper } from "@/core/services/stringHelper.ts";
import { AccessWithOtpService } from "@/core/services/accessWithOtp.ts";
import { CreateOtpAccessRequestService } from "@/core/services/createOtpAccessRequest.ts";
import BlockableButton from "@/components/shared/blockableButton.vue";
import  TokenService  from "@/core/services/tokenService.ts"
let accessWithOtpService!: AccessWithOtpService;
let createOtpAccessRequestService!: CreateOtpAccessRequestService;
export default defineComponent({
    name: "ResetPwdOTPSent",
    data() {
        const resources = inject(Services.Resources) as Resources;
        const validationRulesService = inject(Services.ValidationRules) as ValidationRulesService;
        accessWithOtpService = inject(Services.AccessWithOtp) as AccessWithOtpService;
        const stringHelper = inject(Services.StringHelper) as StringHelper;  
        createOtpAccessRequestService = inject(Services.CreateOtpAccessRequest) as CreateOtpAccessRequestService;
       const newEmail = TokenService.getEmail() || '';
       
        return {
            buttonBack: resources.buttons.back,
            checkYourEmail: resources.titles.checkYourEmail,
            otpEmailSent: stringHelper.formatValues(resources.messages.otpEmailSent, [newEmail]),
            enterCode: resources.messages.enterCode,
            otpValid: resources.messages.otpValid,
            emailWithoutAccess: stringHelper.formatValues(resources.messages.emailWithoutAccess, [newEmail]),
            otpCodeExpiredMessage: resources.messages.otpCodeExpiredMessage,
            otpCodeWrongText: resources.messages.otpCodeWrong,
            otpCodeLabel: resources.labels.otpCode,
            otpCodeValue: '',
            next: resources.buttons.goIntroduceOtpCode,
            vProps: {
                variant: "outlined",
                density: "compact",
            },
            otpCodeRules: validationRulesService.getValidationRules().otpCode,
            loading: false,
            errorTypes: [ErrorType.OtpCode, ErrorType.OtpLogin],
            buttonSave: resources.buttons.save,
            isValid: false,
            hasAccountReachedItsLimit: false,
            otpCodeExpired: false,
            otpCodeWrong: false,
            clickHereText: resources.links.clickHere,
            requestNewCodeText: resources.links.requestNewCode,
            newEmail : "",
        }
    },
    methods: {
        async createOtpAccess(){
            const email = TokenService.getEmail() || '';        
            if (typeof email !== 'string' || !email) {
        console.error("Email not found or is not a valid string in query parameters");
        return;
      }
      this.newEmail = email;
            const response =  await createOtpAccessRequestService.handle({
        email: email,
      }, false);
      if(response){
        return;
      }
        },
        async submitForm() {
            if (!this.isValid)
                return;
            this.loading = true;
            this.otpCodeExpired = false;
            this.otpCodeWrong = false;
            const password = TokenService.getPassword() || '';
                const response = await accessWithOtpService.handle({ code: this.otpCodeValue,email: this.newEmail, password:password }, false);
                if (response.isSuccess) {
                    this.loading = false; 
                    console.log(response);
                    window.location.href = this.getContinueEndpoint();
                    return;
                }
                else{
                    this.otpCodeWrong = true;
                }
            this.loading = false;
        },
        async resendCode() {
            this.createOtpAccess();
        },
        resetOtpCodeWrongFlag() {
            setTimeout(() => {
                this.otpCodeExpired = true;
            }, 180000);
        },
         getContinueEndpoint() {
        const state = TokenService.getState() || '';
        return `${process.env.VUE_APP_AUTH0_SERVER_URI}/continue?state=${state}`;
    }

    },
    mounted() {
    this.createOtpAccess();
    this.resetOtpCodeWrongFlag();
  },
    components: {
        ErrorsManager,
        RadialSpinner,
        BlockableButton
    },
});
