import { createWebHistory, createRouter } from "vue-router";
import LoginNode from '../components/login.vue'
import ContactForm from '../components/contactForm/contactForm.vue'
import EmailVerificationPending from '../components/emailVerificationPending.vue'
import AuthorizeNode from '../components/authorize.vue'
import UserProfile from '../components/userProfile/userProfile.vue'
import Dashboard from '../components/userProfile/children/dashboard.vue'
import UpdateName from '../components/userProfile/children/updateName.vue'
import UpdateEmail from '../components/userProfile/children/updateEmail.vue'
import UpdateEmailOtpSent from '../components/userProfile/children/updateEmailOtpSent.vue'
import UpdatePassword from '../components/userProfile/children/updatePassword.vue'
import PasswordUpdated from "../components/userProfile/children/passwordUpdated.vue"
import EmailUpdated from "../components/userProfile/children/emailUpdated.vue"
import PersonalInfoUpdated from "../components/userProfile/children/personalInfoUpdated.vue"
import ContactFormSent from "../components/contactForm/contactFormSent.vue"
import CookiesPage from "../components/CookiesPage.vue"
import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorsService } from "./services/errorsService.ts";
import { BlockNavigationService } from "./services/blockNavigationService.ts";
import { JwtsWrapper } from "./services/_jwtsWrapper.ts";
import ResetPassword from "@/components/resetPassword/resetPassword.vue";
import ResetPwdOTPSent from "@/components/resetPassword/resetPwdOTPSent.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginNode,
  },
  {
    path: "/authorize",
    name: "Authorize",
    component: AuthorizeNode,
  },
  {
    path: "/contactForm",
    component: ContactForm,
    name: "ContactForm",
  },
  {
    path: "/contactForm/contactFormSent",
    component: ContactFormSent,
    name: "ContactFormSent",
  },
  {
    path: "/emailVerificationPending",
    name: "EmailVerificationPending",
    component: EmailVerificationPending,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/resetPwdOTPSent",
    name: "ResetPwdOTPSent",
    component: ResetPwdOTPSent,
  },
  {
    path: "/userProfile",
    name: "UserProfile",
    component: UserProfile,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: "DashboardComponent",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: 'changeName',
        component: UpdateName,
        meta: { requiresAuth: true }
      },
      {
        path: 'changeName/personalInfoUpdated',
        component: PersonalInfoUpdated,
        meta: { requiresAuth: true }
      },
      {
        path: 'changeEmail',
        component: UpdateEmail,
        meta: { requiresAuth: true }
      },      
      {
        path: 'changeEmail/otpSent',
        component: UpdateEmailOtpSent,
        meta: { requiresAuth: true }
      },
      {
        path: 'changePassword',
        component: UpdatePassword,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/userProfile/changePassword/passwordUpdated",
    name: 'passwordUpdated',
    component: PasswordUpdated
  },
  {
    path: "/userProfile/changeEmail/emailUpdated",
    name: 'emailUpdated',
    component: EmailUpdated
  },
  {
    path: "/cookies",
    name: "cookies",
    component: CookiesPage
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const guardian =
  (auth0: Auth0Plugin, errorsService: ErrorsService, blockNavigationService: BlockNavigationService) => (to: any, from: any, next: any) => {
    if (blockNavigationService.isNavigationBlocked)
      return;

    errorsService.deleteAll();


    //Try to launch check session process for auth needed route
    JwtsWrapper.launchCheckSession(auth0);

    const notNavigatingToEmailVerificationPending = to.matched.some(
      (record: any) => record.name !== "EmailVerificationPending"
    );

    const navigatingToEmailVerificationPending = to.matched.some(
      (record: any) => record.name === "EmailVerificationPending"
    );

    const noNeededAuthAndNotEmailVerificationPendingRoute =
      !to.matched.some((record: any) => record.meta.requiresAuth) &&
      notNavigatingToEmailVerificationPending;

    const auth0AuthenticatedAndEmailVerified =
      auth0.isAuthenticated.value && auth0.idTokenClaims?.value?.email_verified;

    const auth0AuthenticatedAndEmailNotVerified =
      auth0.isAuthenticated.value && !(auth0.idTokenClaims?.value?.email_verified ?? true);


    if (
      noNeededAuthAndNotEmailVerificationPendingRoute ||
      (auth0AuthenticatedAndEmailVerified &&
        notNavigatingToEmailVerificationPending) ||
      (auth0AuthenticatedAndEmailNotVerified &&
        navigatingToEmailVerificationPending)
    ) {
      next();
      return;
    } else if (
      auth0AuthenticatedAndEmailNotVerified &&
      notNavigatingToEmailVerificationPending
    ) {
      next({
        name: "EmailVerificationPending",
      });
      return;
    }

    const nextRoute: any = {
      name: "Login",
    };
    if (to.matched.length) {
      const lastPath = to.matched[to.matched.length - 1].path;
      if (lastPath !== "/emailVerificationPending") {
        nextRoute.query = {
          intendedAccessPath: encodeURIComponent(`${lastPath}${location.search}`),
        };
      }
    }

    next(nextRoute);
  };
